// 3rd-party Plugins
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

* {
  font-family: 'Rubik', sans-serif !important;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined' !important;
}
.material-icons {
  font-family: 'Material Icons' !important;
}

// Header Skins
.kt-header-base-light {
    @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
    @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
    @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}
.kt-header-menu-dark {
    @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";


@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

.cursor-pointer {
  cursor: pointer;
}

.kt-aside-dark .aside-user-profile {
  color: #fff;
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.kt-aside__brand-detail {
  img {
    height: 29px;
    width: 26px;
  }
}
.kt-aside--enabled {
  &:not(.kt-aside--minimize) {
    .aside-user-profile {
      // added animation because suddenly showing text caused it to wrap as
      // sidebar don't have enough width because it's still expanding
      .username {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
      }
    } 
  }
}

.fade-in {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.kt-aside--minimize {
  &:not(.kt-aside--on) {
    .aside-user-profile {
      .username {
        display: none;
      }
    } 
    .kt-aside__brand-detail {
      display: none !important;
    }
  }

  &.kt-aside--enabled .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon {
    margin-right: 0 !important;
  }
}

.aside-user-profile {
  min-height: 115px;

  @include kt-rounded {
    border-radius: $kt-border-radius;
    -webkit-border-radius: $kt-border-radius;
    -moz-border-radius: $kt-border-radius;
    -ms-border-radius: $kt-border-radius;
    -o-border-radius: $kt-border-radius;
  }
  
  .profile-picture {
    align-self: center;
    max-height: $kt-header-topbar-user-avatar-size;
    
    @include kt-rounded {
      border-radius: $kt-border-radius;
      -webkit-border-radius: $kt-border-radius;
      -moz-border-radius: $kt-border-radius;
      -ms-border-radius: $kt-border-radius;
      -o-border-radius: $kt-border-radius;
}
  }
}

.rounded-10px {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

body.element-settings-visible {
  & #root {
    pointer-events: none;
  }
}

.rounded-card {
  border-radius: 8px !important;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  &:not(.disabled) {
    color: kt-state-color(brand);
  }
  &:not([disabled]) {
    color: kt-state-color(brand);
  }

  &.MuiSelect-select {
    min-height: 18px; // select somehow is not maintaining height of 40px = (18 + vertical margin of 11px)
  }

  background: rgba(224, 231, 255, .2);
  padding: 11px 16px;
  font-size: 15px;
}

.dark-custom-input {
  .MuiFormLabel-root {
    font-size: 14px;
    color: kt-base-color(label, 4);
  }

  .MuiOutlinedInput-root {
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    background: white;

    input, textarea, .MuiSelect-root {
      color: kt-state-color(brand, dark) !important;
      background: transparent;
    }
    input::placeholder, textarea::placeholder {
      opacity: 1;
      color: kt-state-color(brand, dark);
    }
    input, .MuiSelect-root {
      height: 1.5em;
    }
    
    &:not(.blue-border) {
      &:not(.Mui-focused) {
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: rgba(0, 0, 0, 0.4) !important;
          }  
        }
  
        .MuiOutlinedInput-notchedOutline {
          border-color: rgba(0, 0, 0, 0.4) !important;
        }
  
        fieldset {
          border-color: kt-state-color(secondary, base) !important;
        }
      }
  
      &.Mui-focused {
        fieldset {
          border-color: rgba(0, 0, 0, 0.4) !important;
        }
      }
    }
    &.blue-border {
      &:not(.Mui-focused) {
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: $dark-blue-border !important;
          }  
        }
  
        .MuiOutlinedInput-notchedOutline {
          border-color: $dark-blue-border !important;
        }
  
        fieldset {
          border-color: $dark-blue-border !important;
        }
      }
  
      &.Mui-focused {
        fieldset {
          border-color: $dark-blue-border !important;
        }
      }
    }

    &.MuiOutlinedInput-multiline {
      padding: 0;
    }
  }
}

.custom-input {
  .MuiFormLabel-root {
    font-size: 12px;
    color: kt-base-color(label, 1);
    text-transform: uppercase;
  }

  .MuiOutlinedInput-root {
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    
    &:not(.Mui-focused) {
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: #d3d5e0 !important;
        }  
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: $blue-border !important;
      }

      fieldset {
        border-color: kt-state-color(secondary, base) !important;
      }
    }

    &.Mui-focused {
      fieldset {
        border-color: kt-state-color(brand, base) !important;
      }
    }

    &.MuiOutlinedInput-multiline {
      padding: 0;
    }
  }
}

.custom-input-with-start-adornment {
  .MuiFormLabel-root {
    font-size: 12px;
    color: kt-base-color(label, 1);
    text-transform: uppercase;
  }

  .MuiOutlinedInput-root {
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;

    fieldset {
      background: rgba(224, 231, 255, 0.2);
    }

    .MuiOutlinedInput-input {
      background: inherit;
      padding-left: 0;
    }
    
    &:not(.Mui-focused) {
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: #d3d5e0 !important;
        }  
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: $blue-border !important;
      }

      fieldset {
        border-color: kt-state-color(secondary, base) !important;
      }
    }

    &.Mui-focused {
      fieldset {
        border-color: kt-state-color(brand, base) !important;
      }
    }

    &.MuiOutlinedInput-multiline {
      padding: 0;
    }
  }
}

.dark-custom-input-with-start-adornment {
  .MuiFormLabel-root {
    font-size: 14px;
    color: kt-base-color(label, 4);
  }

  .MuiOutlinedInput-root {
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    background: white;

    input {
      height: 1.5em;
      color: kt-state-color(brand, dark) !important;
      background: transparent;
    }
    input::placeholder {
      opacity: 1;
      color: kt-state-color(brand, dark);
    }

    .MuiOutlinedInput-input {
      background: inherit;
      padding-left: 0;
    }
    
    &:not(.blue-border) {
      &:not(.Mui-focused) {
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: rgba(0, 0, 0, 0.4) !important;
          }  
        }
  
        .MuiOutlinedInput-notchedOutline {
          border-color: rgba(0, 0, 0, 0.4) !important;
        }
  
        fieldset {
          border-color: rgba(0, 0, 0, 0.4) !important;
        }
      }
  
      &.Mui-focused {
        fieldset {
          border-color: rgba(0, 0, 0, 0.4) !important;
        }
      }
    }
    &.blue-border {
      &:not(.Mui-focused) {
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: $dark-blue-border !important;
          }  
        }
  
        .MuiOutlinedInput-notchedOutline {
          border-color: $dark-blue-border !important;
        }
  
        fieldset {
          border-color: $dark-blue-border !important;
        }
      }
  
      &.Mui-focused {
        fieldset {
          border-color: $dark-blue-border !important;
        }
      }
    }

    &.MuiOutlinedInput-multiline {
      padding: 0;
    }
  }
}

.btn {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

$flexes: 1,2,3,4,5;
.flex {
  @each $flexSize in $flexes {
    &-#{$flexSize} {
      flex: $flexSize !important;
    }
  }
}

$fontSizes: 5,10,15,20,25,30,35,40,45,50;
@each $fontSize in $fontSizes {
  $labelFragment: $fontSize;

  @if $fontSize <= 5 {
    $labelFragment: '#{$labelFragment}px'
  }

  .p-#{$labelFragment} {
    padding: #{$fontSize}px !important;
  }
  .m-#{$labelFragment} {
    margin: #{$fontSize}px !important;
  }
  .pl-#{$labelFragment} {
    padding-left: #{$fontSize}px !important;
  }
  .ml-#{$labelFragment} {
    margin-left: #{$fontSize}px !important;
  }
  .pr-#{$labelFragment} {
    padding-right: #{$fontSize}px !important;
  }
  .mr-#{$labelFragment} {
    margin-right: #{$fontSize}px !important;
  }
  .pt-#{$labelFragment} {
    padding-top: #{$fontSize}px !important;
  }
  .mt-#{$labelFragment} {
    margin-top: #{$fontSize}px !important;
  }
  .pb-#{$labelFragment} {
    padding-bottom: #{$fontSize}px !important;
  }
  .mb-#{$labelFragment} {
    margin-bottom: #{$fontSize}px !important;
  }
  .px-#{$labelFragment} {
    padding-left: #{$fontSize}px !important;
    padding-right: #{$fontSize}px !important;
  }
  .mx-#{$labelFragment} {
    margin-left: #{$fontSize}px !important;
    margin-right: #{$fontSize}px !important;
  }
  .py-#{$labelFragment} {
    padding-top: #{$fontSize}px !important;
    padding-bottom: #{$fontSize}px !important;
  }
  .my-#{$labelFragment} {
    margin-top: #{$fontSize}px !important;
    margin-bottom: #{$fontSize}px !important;
  }
  .f-#{$labelFragment} {
    font-size: #{$fontSize}px !important;
  }
}

$borderSizes: 1,2,3,4,5;
@each $borderSize in $borderSizes {
  .border-#{$borderSize} {
    border-width: #{$borderSize}px !important;
  }
}

$fontSizes: 11,12,14,16,18,22,24,32;
@each $fontSize in $fontSizes {
  .f-#{$fontSize}px {
    font-size: #{$fontSize}px !important;
  }
}

$shades: 1,2,3,4;
@each $shade in $shades {
  .label-#{$shade} {
    color: kt-base-color(label, $shade) !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
.btn-disabled, .disabled {
  pointer-events: none;
  background-color: kt-base-color(label, 1) !important;
  color: white !important;
}
.btn-secondary {
  background-color: kt-base-color(label, 1) !important;
  color: white !important;
}

.text-primary-dark {
  color: kt-state-color(brand, dark) !important;
}

.document-card {
  background-color: kt-state-color(brand, bg) !important;
  
  .title {
    input {
      font-size: 12px;
      text-decoration: underline;
    }
  }
  .status {
    font-size: 12px;
    margin-top: 10px;
    color: kt-base-color(label, 1);
  }
  .description {
    .MuiInputBase-root {
      padding: 0;
    }
    .MuiInputBase-input {
      padding: 10px;
    }
  }
  .file-icon {
    .filename {
      font-size: 10px;
    }
    .uploaded-date {
      font-size: 8px;
    }
    img {
      height: 80px;
    }

    margin-top: 20px;
    color: kt-base-color(label, 1);
  }
  .actions {
    margin-top: 20px;

    .save-note-checkbox {
      padding: 0;
      margin-right: 10px;
    }
  }
  .footer-buttons {
    margin-top: 10px;
  }
  .save {
    margin-top: 10px;
  }

  .MuiCardContent-root {
    padding: 0 !important;
  }
}

.condensed-textarea {
  .MuiInputBase-root {
    textarea {
      padding: 10px;
    }
  }
}

.MuiTextField-bg-white {
  .MuiOutlinedInput-multiline {
    padding: 0;
  }

  .MuiInputBase-root {
    background: white !important;
    
    .MuiInputBase-input  {
      background: white !important;
      border-radius: 8px !important;
    }
  }
}

.user-avatar {
  height: 60px !important;
  width: 60px !important;
  background-color: kt-state-color(brand, bg) !important;
  color: kt-state-color(brand) !important;
}

button.btn.btn-secondary.user-menu {
  &:hover, &:active {
    background: transparent;
    background-color: transparent;
  }
}

.input-label {
  color: kt-base-color(label, 1);
  text-transform: uppercase;
  font-size: 12px;
}

.dark-input-label {
  color: kt-base-color(label, 4);
  font-size: 14px;
}

.provider-title {
  font-size: 16px;
  color: kt-base-color(label, 4);
}

.provider-card {
  padding: 15px;

  .request-settings-container {
    margin-top: 20px;
  }
}

.request-list-container {
  .action-button-container {
    margin: 30px 0;
  }
}

.template-card {
  .form {
    margin-top: 25px;
  }
}
.template-title {
  font-size: 15px;
  color: kt-base-color(label, 4);
}


.provider-container {
  .mark-completed {
    margin-top: 25px;
  }

  .documents-container {
    margin-top: 25px;
  }
}

.mark-not-required-container {
  .MuiInputBase-root {
    padding: 0;
  }
}

.image-preview {
  .react-transform-component {
    height: 100%;
  }

  .react-transform-element {
    height: 80vh;
    display: flex;
    align-items: center;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.preview-container {
  .loading {
    position: relative;

    &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      border-radius: 50%;
      animation: kt-spinner .5s linear infinite;
      border: 2px solid kt-get($kt-state-colors, brand, base);
      border-right: 2px solid transparent;
			left: 50%;
      margin-left: -(calc(kt-get($kt-spinner-config, size, lg)/2));
    }

  	@include kt-spinner-size(lg);
  }
}

.preview-container {
  [role=toolbar] {  
    display: none;
  }
}

div[role=tooltip] {
  z-index: 999;
}

.white-download-icon {
  path {
    fill: kt-get($kt-state-colors, light, base);
  }
}

.custom-input.input-phone {
  .MuiInputBase-adornedStart {
    padding-left: 0;

    .MuiInputAdornment-root {
      margin: 0;

      .MuiButtonBase-root {
        padding: 12px;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.file-item {
  .filename {
    word-break: break-all;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: kt-state-color(brand, bg) !important;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
}

.MuiSnackbarContent-action  {
  @include kt-mobile {
    margin: 0 auto !important;
  }
}

// custom styles
$fontSizes: 10px, 15px, 20px, 25px, 30px, 35px, 40px, 45px, 50px;

@each $fontSize in $fontSizes {
  .f-#{$fontSize} {
    font-size: $fontSize !important;
  }
  .m-#{$fontSize} {
    margin: $fontSize !important;
  }
  .mt-#{$fontSize} {
    margin-top: $fontSize !important;
  }
  .mb-#{$fontSize} {
    margin-bottom: $fontSize !important;
  }
  .my-#{$fontSize} {
    margin-top: $fontSize !important;
    margin-bottom: $fontSize !important;
  }
  .ml-#{$fontSize} {
    margin-left: $fontSize !important;
  }
  .mr-#{$fontSize} {
    margin-right: $fontSize !important;
  }
  .mx-#{$fontSize} {
    margin-left: $fontSize !important;
    margin-right: $fontSize !important;
  }

  .p-#{$fontSize} {
    padding: $fontSize !important;
  }
  .pt-#{$fontSize} {
    padding-top: $fontSize !important;
  }
  .pb-#{$fontSize} {
    padding-bottom: $fontSize !important;
  }
  .py-#{$fontSize} {
    padding-top: $fontSize !important;
    padding-bottom: $fontSize !important;
  }
  .pl-#{$fontSize} {
    padding-left: $fontSize !important;
  }
  .pr-#{$fontSize} {
    padding-right: $fontSize !important;
  }
  .px-#{$fontSize} {
    padding-left: $fontSize !important;
    padding-right: $fontSize !important;
  }
}

$maxLineOptions: 1, 2, 3, 4, 5;

@each $maxLine in $maxLineOptions {
  .max-line-#{$maxLine} {
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $maxLine;
    -webkit-box-orient: vertical;
  }
}

.ls-04 {
  letter-spacing: 0.04em;
}
.text-transform-uppercase {
  text-transform: uppercase;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}
.fw-300 {
  font-weight: 300;
}

.opacity-0 {
  opacity: 0 !important;
}
.opacity-30 {
  opacity: 0.3 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-100 {
  opacity: 1 !important;
}

.h-0 {
  height: 0 !important;
}

.pointer-events-none {
  pointer-events: none;
}
.pointer-events-all {
  pointer-events: all;
}

.bg-light-blue {
  background: rgba(224, 231, 255, 0.2) !important;
  background-color: rgba(224, 231, 255, 0.2) !important;
}
.text-light-text {
  color: #858FA3
}

.border-gray {
  border: 2px solid $blue-border;
}
.border-blue {
  border: 2px solid $dark-blue-border;
}

.text-decoration-underline {
  text-decoration: underline;
}

pre.wrappable {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;
}

.line-height-inherit {
  line-height: inherit !important;
}

fieldset:disabled {
  pointer-events: none;
}

.invisible-input-container {
  input, input:focus, input:focus-visible {
    border: none;
    outline: none;
    background: transparent;
  }
}

.resize-none {
  resize: none;
}
.break-word {
  word-break: break-word;
  // word-break: break-all;
}

.w-65 {
  width: 65% !important;
}

.white-space-pre-line {
  white-space: pre-line;
}

$font-weights: (
  "light": 300,
  "regular": 400,
  "medium": 500,
  "semi-bold": 600,
  "bold": 700,
  "extra-bold": 800,
  "black": 900,
);

@each $name, $font-weight in $font-weights {
  .font-weight-#{$name} {
    font-weight: $font-weight !important;
  }
}

.modern-input-container {
  & input {
    color: kt-state-color(brand, dark) !important;
  }
  & textarea {
    color: kt-state-color(brand, dark) !important;
  }
}

.border-primary-blink {
  border: 1px transparent solid;
  animation: blink-primary-border 1s;
  animation-iteration-count: 4;
}

.kaddim-date-picker-calendar {
  & .MuiPickersCalendarHeader-dayLabel {
    color: kt-state-color(brand, dark);
  }
  & .MuiPickersDay-day:not(.MuiPickersDay-dayDisabled):not(.MuiPickersDay-current):not(.MuiPickersDay-daySelected) {
    color: kt-state-color(brand, dark);
  }
}

@keyframes blink-primary-border { 50% { border-color: kt-state-color(brand); }  }